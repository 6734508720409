import React from "react";
import CustomError from "../../components/notfound";

function index() {
  return <CustomError statusCode={404} />;
}

export const getStaticProps = async () => {
  return {
    props: {},
  };
};

export default index;
